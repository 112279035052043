import { useCallback, useState, useEffect, useRef } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Slider from 'react-slick';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, Checkbox } from '@mui/material';
import './productCarousel.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getDefaultBrand, getProductByRating, getProducts } from '../../../api/service';
import OneButton from '../../oneButton/oneButton';

function ProductCarousel(props) {

  const { payload } = props
  const { id } = payload;
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsByMarque, setProductsByMarque] = useState([]);
  const [selectedMarque, setSelectedMarque] = useState('Toutes les marques');
  const [filterMarque, setFilterMarque] = useState('Toutes les marques');
  const brands = ['Toutes les marques', 'YBERA', 'DAVINES', 'Kérastase Shampoo', 'Kérastase', 'WELLA', "L'oréal"];
  const sliderRef = useRef(null);

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext(); // Utilisez la fonction slickNext() du Slider pour passer au slide suivant
    }
  };

  const handleCheckedProducts = (uid, addFlag) => {
    if (addFlag) {
      setCheckedProducts((prevCheckedProducts) => [...prevCheckedProducts, uid]);
    } else {
      setCheckedProducts((prevCheckedProducts) =>
        prevCheckedProducts.filter((productUid) => productUid !== uid)
      );
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        let marque
        filterMarque === 'Toutes les marques' ? marque = null : marque = filterMarque
        const data = await getProductByRating(id, { marque });
        setProducts(data);
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };
    fetchData();
  }, [id, filterMarque]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let id = urlParams.get('id')
        if (!id)
          id = '2085475'
        const res = await getDefaultBrand(id);
        if (res.default_brand)
          setFilterMarque(res.default_brand)
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };
    fetchData();
  }, []);

  const getProductsByMarque = useCallback(async () => {
    try {
      let marque;
      selectedMarque === 'Toutes les marques' ? (marque = null) : (marque = selectedMarque);
      const data = await getProducts({ marque });
      setProductsByMarque(data);
      goToNextSlide();
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  }, [selectedMarque]);

  const settings = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: false,
    animation: 'slide'
  };

  const style = {
    background: 'white',
    borderRadius: '8px',
    padding: '10px',
    cursor: 'pointer',
    minWidth: '200px',
    height: '25px'
  };

  return (
    <div style={{ width: '90%', height: 'fit-content', margin: 'auto' }}>
      <FormControl style={{ marginTop: '15px', marginBottom: '15px' }}>
        <Select
          variant='standard'
          id="marque"
          value={filterMarque}
          label="Selectionnez une marque"
          onChange={(e) => setFilterMarque(e.target.value)}
          style={style}
          disableUnderline={true}
        >
          {brands.map((brand) => (
            <MenuItem key={brand} value={brand}>
              {brand}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {products && products.length > 0 ? (
        <>
          <Slider ref={sliderRef} {...settings}>
            {products.map((item, i) => (
              <div key={i} >
                {/* Content of your slider item */}
                <Item
                  item={item}
                  handleCheckedProducts={handleCheckedProducts} />
              </div>
            ))}
            {
              <div key={4} >
                <div style={{
                  width: '99%',
                  height: '120px',
                  backgroundColor: 'rgb(245, 245, 245)',
                  borderRadius: '12px',
                  border: '1px solid #000',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <FormControl>
                    <InputLabel htmlFor="your-select">"Selectionnez une marque"</InputLabel>
                    <Select
                      variant='standard'
                      id="your-select"
                      value={selectedMarque}
                      label="Selectionnez une marque"
                      onChange={(e) => setSelectedMarque(e.target.value)}
                      style={style}
                      disableUnderline={true}
                    >
                      <MenuItem disabled value="">
                        <em>Selectionnez votre marque</em>
                      </MenuItem>
                      {brands.map((brand) => (
                        <MenuItem key={brand} value={brand}>
                          {brand}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div>
                    <button
                      disabled={selectedMarque ? false : true}
                      onClick={() => {
                        getProductsByMarque();
                      }}
                      style={{
                        backgroundColor: selectedMarque ? '#8b9cd2' : '#f0f0f0',
                        color: selectedMarque ? 'white' : ' #a8a8a8',
                        marginTop: '12px',
                        auto: 'auto',
                        border: 'none'
                      }}>
                      Voir tous les soins
                    </button>
                  </div>

                </div>
              </div>
            }
            {productsByMarque && productsByMarque.length > 0 && productsByMarque.map((item, i) => (
              <div key={i}>
                {/* Content of your slider item */}
                <Item
                  item={item}
                  handleCheckedProducts={handleCheckedProducts} />
              </div>
            ))}
          </Slider>
        </>
      )
        : (
          <div style={{ textAlign: 'center', marginTop: '50px', color: '#888' }}>
            <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Aucun produit disponible</p>
            {/* Add any additional styling as needed */}
          </div>
        )}
      <div style={{ marginTop: '10px' }}>
        <OneButton
          payload={payload}
          checkedProducts={checkedProducts}>
        </OneButton>
      </div>
    </div>
  );
}

function Item(props) {
  const [isChecked, setChecked] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const openPopup = (product) => {
    setSelectedProduct(product);
    setPopupOpen(true);
  };

  const style = {
    container: {
      width: '99%',
      height: '100%',
      borderRadius: '12px',
      border: '1px solid #000',
      overflow: 'hidden'
    },
    product: {
      background: 'white',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'left'
    },
    image: {
      width: '25%',
      height: '70%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',
      marginLeft: '3%',
      marginRight: '3%',
    },
    titre: {
      fontWeight: '700',
      fontSize: '10px'
    },
    description: {
      color: '#5b6577',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal'
    }
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleCheckboxChange = () => {
    props.handleCheckedProducts(props.item.uid, !isChecked)
    setChecked(!isChecked);
  };

  return (
    <div style={style.container}>
      <div onClick={() => openPopup(props.item)} style={style.product}>
        <img src={`https://api.effimetrix.care/images/${props.item['uid']}.png`} alt={props.item['Nom du soin :']} style={style.image} />
        <div>
          <h2 style={style.titre}>{props.item['Nom du soin :']}</h2>
          <p style={style.description}>{props.item['Déscription courte'].slice(0, 200) + '...'}</p>
          <div onClick={(e) => e.stopPropagation()}>
            <span style={style.description}>Sélectionner ce soin</span>
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              color="primary"
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        </div>

      </div>
      <ProductPopup open={popupOpen} onClose={closePopup} product={selectedProduct} />
    </div>
  );
}


// function Item(props) {
//   const [isChecked, setChecked] = useState(false);
//   const [popupOpen, setPopupOpen] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const openPopup = (product) => {
//     setSelectedProduct(product);
//     setPopupOpen(true);
//   };

//   const style = {
//     product: {
//       borderRadius: '12px',
//       background: 'white',
//       height: '150px',
//       width: '80%',
//       margin: '0 auto 15px',
//       display: 'flex',
//       alignItems: 'flex-start',
//       justifyContent: 'space-between',
//       padding: '7px 18px',
//       textAlign: 'center'
//     }
//   }

//   const closePopup = () => {
//     setPopupOpen(false);
//   };

//   const handleCheckboxChange = () => {
//     props.handleCheckedProducts(props.item.uid, !isChecked)
//     setChecked(!isChecked);
//   };

//   return (
//     <div style={{ backgroundColor: "white" }}>
//       <Paper onClick={() => openPopup(props.item)} style={style.product}>
//       <img src={`https://api.effimetrix.care/images/${props.item['uid']}.png`} alt={props.item['Nom du soin :']} style={{ maxWidth: '100%', maxHeight: '200px', margin: 'auto' }} />
//         <h2>{props.item['Nom du soin :']}</h2>
//         <p>{props.item['Déscription courte']}</p>
//       </Paper>
//       {/* <Checkbox
//         checked={isChecked}
//         onChange={handleCheckboxChange}
//         color="primary"
//         inputProps={{ 'aria-label': 'controlled' }}
//       /> */}
//       <ProductPopup open={popupOpen} onClose={closePopup} product={selectedProduct} />
//     </div>
//   );
// }

function ProductPopup({ open, onClose, product }) {

  if (!product) {
    return null; // or you can render a message or placeholder
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', width: '85%', maxHeight: '500px' }}>
        <img
          src={`https://api.effimetrix.care/images/${product['uid']}.png`}
          alt={product['Nom du soin :']}
          style={{ maxWidth: '100%', maxHeight: '200px', margin: 'auto' }}
        />
        <DialogContentText style={{ whiteSpace: 'pre-wrap', font: '400 14px/20px Roboto, Helvetica Neue, sans-serif', textAlign: 'center', marginTop: '10px', color: 'black' }}>{product['Nom du soin :']}</DialogContentText>
        <DialogContentText style={{ whiteSpace: 'pre-wrap', font: '400 14px/20px Roboto, Helvetica Neue, sans-serif', textAlign: 'center', marginTop: '10px', width: '80%', color: 'black' }}>{product['Déscription longue']}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );

}


export default ProductCarousel;
