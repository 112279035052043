import { findProfileByEmail, getToken, registerProfile } from "../api/service";
import {titleNormalized} from "../select";

export const saveResWidget = (message, setState ) => {
    const save = {
        message: message,
        type: 'widget',
        id: new Date().getTime()
    }

    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, save]
    }))
  }

export const capitalizeFirstLetter = (inputString) => {
  if(inputString == null)
    return;
  
    if (inputString.length === 0) {
      return inputString;
    }
    return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
  }

  export const handleVideo = (src, text, time) => {
  if(titleNormalized === 'chatbot-ns') {
    src = src.replace('-ns', '');
  } else if(titleNormalized === 'chatbot-hair-ybera') {
    src = src.replace('-ybera', '');
  }

    let video = document.getElementById('vid');
    // let video_text_span = document.getElementById('vid_text_span');
    let container_video = document.getElementById('container_vid')
  
    video.src = src
    // video_text_span.style.animation = 'none';
    // void video_text_span.offsetWidth; /* trigger reflow */
    // video_text_span.style.animation = null;
    // video_text_span.textContent = text
    container_video.style.transform = 'translateY(0)';
    video.play();
    setTimeout(() => {
      container_video.style.transform = 'translateY(1000%)';
    }, time)
  }

  export const registerCustomer = async (firstName, lastName, email, age) => {

    let customer_id

    const data = {
      age: age || null,
      "app": "abbi",
      email: email,
      firstname: firstName || null,
      lastname: lastName,
      group_id: 1,
      pwd: "effimetrix"
    }

    let res = await findProfileByEmail(email)
    if (res.items.length) {
      customer_id = res.items[0].id
    }
    else {
      const tmp = await registerProfile(data)
      customer_id = tmp.id
    }

    const token = await getToken(customer_id)
    return token
    //updateCustomer('token', token.token)

  }
