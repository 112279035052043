import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { createClientMessage } from 'react-chatbot-kit';
import './choices.css';
import { capitalizeFirstLetter, saveResWidget } from '../../utils/utils';
import { WordsHelper } from "../../language";
import { appLanguage, titleNormalized } from "../../select";

const getTextHelper = (key) => {
    return WordsHelper[key][appLanguage]
  }


const Choices = (props) => {
    const { payload, actionProvider, setState } = props;
    const { id, choices } = payload;
    const [inputValue, setInputValue] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const color_hair = ['Auburn', 'Blonds', 'Bruns', 'Chatains', 'Roux', 'Gris _ Blanc ']
    const [loadedImages, setLoadedImages] = useState([]);

    const handleImageLoad = (index) => {
        setLoadedImages(prevState => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
        });
    };


    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(true);
            const lastChild = document.querySelector('.react-chatbot-kit-chat-message-container').lastChild
            lastChild.scrollIntoView()
        }, 2000); // 2000 millisecondes (2 secondes)

        return () => clearTimeout(timeout);
    }, []); // 

    const createChatUserMessage = (msg, options = {}) => {
        const message = createClientMessage(
            msg,
            options
        );
        setState(prevState => ({
            ...prevState,
            messages: [...prevState.messages, message]
        }))
    };

    const button = document.getElementsByClassName("react-chatbot-kit-chat-btn-send")[0];
    const input = document.getElementsByClassName("react-chatbot-kit-chat-input")[0];

    const handleEvent = () => {
        button.removeEventListener("click", clickHandler);
        input.removeEventListener("keydown", keydownHandler);
        input.readOnly = false
        createChatUserMessage(input.value);
        actionProvider.next(id + 1);
        saveResWidget(inputValue, setState);
    };

    const handleChoice = (item) => {
        if (!inputValue.includes((item.text || item))) {
            setInputValue((prevValue) =>
                prevValue ? prevValue + ' ' + getTextHelper('and') + ' ' + (item.text || item) : (item.text || item)
            )
        }

    };

    const handleButtonClick = () => {
        handleEvent();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleEvent();
        }
    };

    const clickHandler = () => {
        handleButtonClick();
    };

    const keydownHandler = (event) => {
        handleKeyDown(event);
    };

    useEffect(() => {
        input.value = inputValue
    }, [inputValue]); // The empty dependency array ensures this effect runs only once on mount

    // Attach event listeners when the component is mounted
    useEffect(() => {
        button.addEventListener("click", clickHandler);
        input.addEventListener("keydown", keydownHandler);
        input.readOnly = true

        // Clean up by removing event listeners when the component is unmounted
        return () => {
           
            button.removeEventListener("click", clickHandler);
            input.removeEventListener("keydown", keydownHandler);
        };
    }, []); // The empty dependency array ensures this effect runs only once on mount

    return (
        isVisible && (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    padding: '0 5%'
                }}
            >
                {choices.map((item, index) => (
                    <Button className='btnChoices' key={index} variant="outlined" onClick={() => handleChoice(item)}>
                        {item.img &&
                            <img className={color_hair.includes(item.img) ? null : 'imgChoices'} src={`/assets/images/hair_icons/${item.img}.png`}
                                width={'42'}
                                alt={item.text || item}
                                onLoad={() => handleImageLoad(index)} />}
                        {(loadedImages[index] || !item.img) && ( // Afficher le texte seulement si l'image est chargée ou si l'élément n'a pas d'image
                            capitalizeFirstLetter(item.text) || capitalizeFirstLetter(item)
                        )}
                    </Button>
                ))}
            </Box>
        )
    );
};

export default Choices;
