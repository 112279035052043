import React, {useEffect, useState} from 'react'
import {Box, Button} from '@mui/material';
import './myDiagnostic.css'
import {Langage} from "../../language";
import {appLanguage} from "../../select";
import {sortScoreArray} from "../../utils/helper";
import {postScores, skinType} from "../../api/service";


const MyDiagnostic = (props) => {

    const [scores, setScores] = useState({})
    const getText = (key) => {
        return Langage[key][appLanguage]
    }

    let traitementScores = {
        "traitementRides": 0,
        "traitementRegulation": 0,
        "traitementSensibilite": 0,
        "traitementEclat": 0,
        "traitementHydratation": 0,
        "traitementTaches": 0,
        "traitementImperfections": 0,
        "traitementPores": 0
    }
    const labels = {
        traitementRides: {right: getText('wrinklesRight'), left: getText('wrinklesLeft'), label: getText('rides')},
        traitementEclat: {right: getText('radianceRight'), left: getText('radianceLeft'), label: getText('eclat')},
        traitementHydratation: {right: getText('hydrationRight'), left: getText('hydrationLeft'), label: getText('hydratation')},
        traitementImperfections: {right: getText('pimplesRight'), left: getText('pimplesLeft'), label: getText('imperfections')},
        traitementPores: {right: getText('poresRight'), left: getText('poresLeft'), label: getText('pores')},
        traitementRegulation: {right: getText('regulationRight'), left: getText('regulationLeft'), label: getText('brillance')},
        traitementSensibilite: {right: getText('sensitivityRight'), left: getText('sensitivityLeft'), label: getText('sensibilite')},
        traitementTaches: {right: getText('pimplesRight'), left: getText('pimplesLeft'), label: getText('taches')}
    }

    useEffect(() => {
        setScores(traitementScores);
        setTimeout(() => {
            const scoresCon = document.querySelector('.scores-con');
            scoresCon.style.height = 'auto';
        }, 2000);
    }, [])

    const setScore = (score, scoreValue) => {
        scores[score] = +scoreValue / 100;
        setScores(scores);
    }

    const goNext = async () => {
        await postScores(scores);
        const res = await skinType(scores, 50);
        const state = {scores,skinType: res.skintype}
        props.setState((prev) => {
            return { ...prev, ...state }
        })
        props.actionProvider.next(getText('start_diag2'), 3)
    }

    return (
        <Box>
            <div className={'scores-con'}>
                <div className='scores'>
                    {
                        Object.keys(scores).map((key, index) => {
                            return <div key={index} className={`score`}>
                                <span className={`score-label`}>{labels[key].label}</span>
                                <div className={`score-bars`}>
                                    <div className={'score-text'}>{labels[key].right}</div>
                                    <input type="range" orient="vertical" onInput={e => {setScore(key, e.target.value)}} step={1} min={0} max={100}/>
                                    <div className={'score-text'}>{labels[key].left}</div>
                                </div>
                            </div>
                        })
                    }
                </div>
                <Button onClick={ () => {goNext()} } variant="contained" className={'analyses-next'}>
                    {getText('ok')}
                </Button>
            </div>
        </Box>
    )
}

export default MyDiagnostic
